<template>
  <div style="height: 100%" class="">
    <div style="height: 100%" class="d-flex">
      <v-card-title class="pt-xl-2 pt-md-1 pb-0">
        <h4
          style="font-weight: unset"
          @mouseover="topRiskDevicesShow"
          @mouseleave="topRiskDevicesoff"
        >
          TOP RISK DEVICES
        </h4>
        <v-select
          class="ml-5 pt-1"
          :items="selectOptions"
          item-text="name"
          item-value="value"
          dense
          outlined
          hide-details=""
          style="font-size: 0.8em; max-width: 50%"
          v-model="selection"
          :@change="getDevices(selection)"
        ></v-select>
      </v-card-title>
    </div>
    <v-card-text
      class="pt-xl-4 pt-md-0 pb-xl-4 pb-md-2"
    >
      <v-simple-table dense style="border-bottom: solid 1px gainsboro">
        <template v-slot:default>
          <thead>
            <tr>
              <th class="text-left pl-1 pr-0">#</th>
              <th
                class="text-center pl-0 pr-0"
                v-for="head in tableHeads"
                :key="head"
              >
                {{ head }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, index) in tableData" :key="item.ip + index">
              <td
                class="pl-1 pr-0"
                style="border-bottom: none"
                v-bind:style="{ height: tableHeight }"
              >
                {{ index + 1 }}
              </td>
              <td
                class="text-center pl-0 pr-0"
                style="border-bottom: none; width: 110px"
                v-bind:style="{ height: tableHeight }"
              >
                {{ item.ip }}
              </td>
              <td
                class="text-center pa-0"
                style="border-bottom: none; width: 15px"
                v-bind:style="{ height: tableHeight }"
              >
                <v-btn
                  class="ghost-icon pa-0"
                  :ripple="false"
                  icon
                  depressed
                  retain-focus-on-click
                  color="Gray"
                  x-small
                  :href="'/#/search/command/ip/' + item.ip"
                >
                  <v-img
                    src="@/assets/right-cirle.png"
                    style="width: 85% !important; height: 85% !important"
                  ></v-img>
                </v-btn>
              </td>
              <td
                class="text-center pl-0 pr-0"
                style="border-bottom: none"
                v-bind:style="{ height: tableHeight }"
              >
                {{ item.country }} ({{ item.city }})
              </td>
              <td
                class="text-center pl-0 pr-0"
                style="border-bottom: none"
                v-bind:style="{ height: tableHeight }"
              >
                {{ item.totalScore }}({{ item.vulnScore }}/{{
                  item.threatScore
                }})
              </td>
            </tr>
          </tbody>
        </template>
      </v-simple-table>
    </v-card-text>
  </div>
</template>

<script>
import axios from "axios";
import { index } from "@/mixins/elastic.js";
import { topDevices } from "@/mixins/queries.js";
import EventBus from "@/plugins/EventBus.js";
import { PTestConn } from "@/mixins/commons.js";

var _ = require("lodash");
export default {
  data() {
    return {
      showhover: false,
      selectedip: {},
      selection: `total_score`,
      selectOptions: [
        { name: `TOTAL SCORE`, value: `total_score` },
        { name: `THREAT SCORE`, value: `threat_score` },
        { name: `VULNERABILITY SCORE`, value: `vuln_score` },
      ],
      tableData: [],
      tableHeads: [`IP Addr`, ``, `Country/City`, `Total Score(vuln/Threat)`],
    };
  },
  mounted() {
    // this.getDevices();
  },
  computed: {
    tableHeight() {
      switch (this.$vuetify.breakpoint.name) {
        case `xs`:
          return "26px";
        case `sm`:
          return "16px";
        case `md`:
          return "20px";
        case `lg`:
          return "21px";
        case `xl`:
          return "31px";
      }
      return "31px";
    },
  },
  methods: {
    topRiskDevicesShow: function () {
      EventBus.$emit(
        "topRiskDevicesShow",
        `You can see the risk Top 10 IP devices with the highest risk according to the Risk Score (Total/Vulnerability/Threat), and can move to the detailed scan result menu(Search) for a specific IP device with a mouse click.`
      );
    },
    topRiskDevicesoff: function () {
      EventBus.$emit("topRiskDevicesShow", null);
    },

    getDevices: function (field = `total_score`) {
      console.log("getDevices");
      const query = topDevices(field);

      axios
        .post(PTestConn, {
          queryurl: index.nmap + "/_search",
          querybody: JSON.stringify(query),
        })
        .then((res) => {
          // console.log('TopDevices:',res);
          this.tableData = res.data.map((x) => {
            const values = x;
            return {
              ip: values._source.geoip.ip,
              country: values._source["xml-nmap"]
                ? values._source["xml-nmap"].geo_api[0].country[0]
                : values._source.geoip.country_name,
              city: values._source["xml-nmap"]
                ? values._source["xml-nmap"].geo_api[0].city
                  ? values._source["xml-nmap"].geo_api[0].city[0]
                  : values._source[
                      "xml-nmap"
                    ].geo_api[0].timezone[0].name[0].split("/")[1].replaceAll("_"," ")
                : values._source.geoip.city_name,
              totalScore: _.round(values._source.score.total_score, 2),
              vulnScore: values._source.score.vuln_score
                ? _.round(values._source.score.vuln_score, 2)
                : 0,
              threatScore: values._source.score.threat_score
                ? _.round(values._source.score.threat_score, 2)
                : 0,
            };
          });
        })
        .finally(() => {});
    },
    mouseover: function () {
      this.showhover = !this.showhover;
    },
    mouseout: function () {
      this.showhover = false;
    },
  },
};
</script>

<style scoped>
.v-text-field--outlined.v-input--dense.v-text-field--outlined
  /deep/
  .v-input__control
  > .v-input__slot {
  min-height: 33px;
  max-height: 33px;
}

.v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
  padding: 0 10px;
}

.v-data-table > .v-data-table__wrapper > table > thead > tr > th {
  padding: 0 10px;
}

.v-text-field--enclosed.v-input--dense:not(
    .v-text-field--solo
  ).v-text-field--outlined
  /deep/
  .v-input__append-inner {
  margin-top: 4px;
}

.v-select.v-input--dense /deep/ .v-select__selection--comma {
  margin: 3px 4px 3px 0;
}
</style>
