<template>
  <v-container ref="gototop" class="scroll-y" id="target">
    <div class="pa-4" v-scroll="ClickEvent">
      <div
        style="font-weight: bold; border-bottom: 1px solid #d3d4e6"
        class="mt-0"
      >
        Port Scan
      </div>
      <div class="px-1 my-2" style="width: 100%">
        <table class="table-md pl-1 mb-4 ml-xl-3">
          <tbody>
            <tr class="vertical-align-top">
              <td class="text-right" style="width: 70px">
                {{ openedPorts }} Ports
              </td>
              <td>
                <span class="score-badge mr-2 opened-bg" style="margin:0px 4px;">OPENED</span>
              </td>
              <td class="">
                <span v-for="(port, index) in ports" :key="port.portid">
                  <span v-if="port.state[0].state == 'open'">
                    <a
                      style="text-decoration-line: none"
                      :href="'#' + port.protocol + '-' + port.portid"
                      @click.prevent="openpanal(port)"
                      ><span v-if="index > 0"> </span> {{ port.protocol }}/{{
                        port.portid
                      }}</a
                    >
                    <span v-if="index < openedPorts - 1">,</span>
                  </span>
                </span>
              </td>
            </tr>
            <tr class="vertical-align-top">
              <td class="text-right" style="width: 70px">
                {{ filteredPorts }} Ports
              </td>
              <td>
                <span class="score-badge mx-1 filtered-bg">FILTERED</span>
              </td>
              <td class="">
                <span
                  v-if="
                    host['xml-nmap'].host[0].ports[0].extraports[0].state ==
                    'filtered'
                  "
                  >{{
                    host["xml-nmap"].host[0].ports[0].extraports[0]
                      .extrareasons[0].reason
                  }}</span
                >
                <span v-for="(port, index) in ports" :key="port.portid">
                  <span v-if="port.state[0].state == 'filtered'">
                    <span v-if="index > 0"> </span>{{ port.protocol }}/{{
                      port.portid
                    }}
                    <span v-if="index < filteredPorts">,</span>
                  </span>
                </span>
              </td>
            </tr>
            <tr class="vertical-align-top">
              <td class="text-right" style="width: 70px">
                {{ closedPorts }} Ports
              </td>

              <td><span class="score-badge mx-1 closed-bg">CLOSED</span></td>
              <td>
                <span
                  v-if="
                    host['xml-nmap'].host[0].ports[0].extraports[0].state ==
                    'closed'
                  "
                  class="mr-1"
                  >{{
                    host["xml-nmap"].host[0].ports[0].extraports[0]
                      .extrareasons[0].reason
                  }}</span
                >
                <span v-for="(port, index) in ports" :key="port.portid">
                  <span v-if="port.state[0].state == 'closed'">
                    <span v-if="index > 0"> </span>{{ port.protocol }}/{{
                      port.portid
                    }}
                    <span v-if="index < closedPorts - 1">,</span>
                  </span>
                  <span v-else-if="port.state[0].state == '! open && filtered'"
                    >resets</span
                  >
                </span>
                <span v-if="closedPorts === 0">--</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div
        v-if="
          criticalScripts.length > 0 ||
          highScripts.length > 0 ||
          mediumScripts.length > 0 ||
          lowScripts.length > 0
        "
      >
        <div
          style="font-weight: bold; border-bottom: 1px solid #d3d4e6"
          class="mt-2"
        >
          Script Scan
        </div>
        <div class="px-1 my-2" style="width: 100%">
          <table class="table-sm mb-4">
            <tbody>
              <tr class="vertical-align-top" v-if="criticalScripts.length > 0">
                <td class="text-right" style="width: 70px">
                  {{ criticalScripts.length }} Scripts
                </td>
                <td>
                  <span class="score-badge mx-1 critical-bg">CRITICAL</span>
                </td>
                <span
                  v-for="(criticalScript, index) in criticalScripts"
                  :key="index"
                  class="pr-1"
                >
                  <a
                    :href="
                      criticalScript.port.portid +
                      '-' +
                      criticalScript.script.id
                    "
                    @click.prevent="
                      openScriptPanel(
                        criticalScript.port,
                        criticalScript.script
                      )
                    "
                  >
                    <span v-if="index > 0">, </span
                    >{{ criticalScript.script.id }}</a
                  >
                </span>
              </tr>

              <tr class="vertical-align-top" v-if="highScripts.length > 0">
                <td class="text-right" style="width: 70px">
                  {{ highScripts.length }} Scripts
                </td>
                <td><span class="score-badge mx-1 high-bg">HIGH</span></td>
                <td class="">
                  <span
                    v-for="(highScripts, index) in highScripts"
                    :key="index"
                    class="pr-1"
                  >
                    <a
                      :href="
                        highScripts.port.portid + '-' + highScripts.script.id
                      "
                      @click.prevent="
                        openScriptPanel(highScripts.port, highScripts.script)
                      "
                    >
                      <span v-if="index > 0">, </span
                      >{{ highScripts.script.id }}</a
                    >
                  </span>
                </td>
              </tr>

              <tr class="vertical-align-top" v-if="mediumScripts.length > 0">
                <td class="text-right" style="width: 70px">
                  {{ mediumScripts.length }} Scripts
                </td>
                <td><span class="score-badge mx-1 medium-bg">MEDIUM</span></td>
                <td class="">
                  <span
                    v-for="(mediumScripts, index) in mediumScripts"
                    :key="index"
                    class="pr-1"
                  >
                    <a
                      :href="
                        mediumScripts.port.portid +
                        '-' +
                        mediumScripts.script.id
                      "
                      @click.prevent="
                        openScriptPanel(
                          mediumScripts.port,
                          mediumScripts.script
                        )
                      "
                    >
                      <span v-if="index > 0">, </span
                      >{{ mediumScripts.script.id }}</a
                    >
                  </span>
                </td>
              </tr>

              <tr class="vertical-align-top" v-if="lowScripts.length > 0">
                <td class="text-right" style="width: 70px">
                  {{ lowScripts.length }} Scripts
                </td>
                <td><span class="score-badge mx-1 low-bg">LOW</span></td>
                <td class="">
                  <span
                    v-for="(lowScripts, index) in lowScripts"
                    :key="index"
                    class="pr-1"
                  >
                    <a
                      :href="
                        lowScripts.port.portid + '-' + lowScripts.script.id
                      "
                      @click.prevent="
                        openScriptPanel(lowScripts.port, lowScripts.script)
                      "
                    >
                      <span v-if="index > 0">, </span
                      >{{ lowScripts.script.id }}</a
                    >
                  </span>
                </td>
              </tr>
              <tr
                v-if="
                  criticalScripts.length === 0 &&
                  highScripts.length === 0 &&
                  mediumScripts.length === 0 &&
                  lowScripts.length === 0
                "
              >
                <td>No Script scan results</td>openport
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div class="mt-4" style="width: 100%">
        <v-expansion-panels accordion multiple flat>
          <v-expansion-panel
            @click="ClickEvent"
            v-for="(port, index) in ports"
            :key="index"
            class="mt-3"
            v-show="port.state[0].state == 'open'"
            aria-expanded=""
          >
            <v-expansion-panel-header
              v-ripple
              :id="port.protocol + '-' + port.portid"
              v-model="isPanelOpen"
              ref="refpanel"
              class="pl-0 pr-0 pt-0 pb-0 v-expansion-panel-header text-uppercase scriptwrap"
              style="font-weight: bold; border-bottom: 1px solid #d3d4e6"
            >
              {{ port.protocol }}/{{ port.portid }} {{ port.service ? port.service[0].name : ''}} 
              <div style="position: relative;">
                <v-icon small
                @mouseover="showTooltip"
                @mouseout="offTooltip"
                style="margin-left:5px; width:16px; display: block !important;"
                v-if="port.service"
                >
                  mdi-help-circle-outline
                </v-icon>
                <div
                  class="desTooltip"
                  style="position: absolute; left:-50px; min-width: 300px; max-width: 500px;"
                  v-html="getServiceDesc(port.service? port.service[0].name : '')"
                >
                </div>
              </div>
            </v-expansion-panel-header>

            <v-expansion-panel-content
              class="mt-2 v-expansion-panel-content"
              id="1"
            >
              <v-btn
                id="gtp"
                style="margine-left: 90%"
                v-if="offsetTop"
                fixed
                elevation="0"
                small
                dark
                fab
                bottom
                right
                color="blue-grey lighten-3"
                @click="toTop"
              >
                <v-icon>mdi-arrow-up-bold-outline</v-icon>
              </v-btn>

              <div style="width: 100%">
                <v-card
                  outlined
                  color="#ECEFF1"
                  style="border: 1px solid #d3d4e6"
                >
                  <v-card-text id="2">
                    <pre
                      id="3"
                      style="
                        width: 100%;             
                        white-space: pre-wrap;
                        word-break: break-word;
                      "
                    >
 {{ host.geoip.ip }} : {{ port.portid }} ({{ port.state[0].reason }})</pre
                    >
                    <pre
                      id="4"
                      style="
                        width: 100%;
                        white-space: pre-wrap;
                        word-break: break-word;
                      "
                      v-for="(service, index) in port.service"
                      :key="
                        index
                      ">{{ service.name }} {{ service.extrainfo }} {{ service.product }} {{ service.ostype }} {{ service.version }} <br><span id="5" v-for="(cpe, index) in service.cpe" :key="index">{{ cpe }}<br></span></pre>
                  </v-card-text>
                </v-card>
                <!-- Example -->
                <div v-if="port.script">
                  <div v-for="(script, index) in port.script" :key="index">
                    <span v-if="port.portid == script.port"
                      >The script is: <code>{{ script }}</code></span
                    >
                    <div
                      :id="port.portid + '-' + script.id"
                      ref="scriptpanal"
                      class="mt-4 pa-0 scriptwrap"
                    >
                      <div class="scriptIcon" style="width:max-content;height:25px;">
                        <div style="float:left;">
                          {{ script.id }} ( S:{{ script.score }} )
                        </div>
                          <v-icon small 
                          class="icon"
                          style="margin-left:4pt; cursor: pointer;"
                          @mouseover="showTooltip"
                          @mouseleave="offTooltip"
                          >
                            mdi-help-circle-outline
                          </v-icon>
                      </div>
                        <div
                          class="desTooltip"
                        >
                            {{getScriptDesc(script.id)}}
                        </div>
                </div>
                 
                    
                    
                    <div>
                      <v-card
                        outlined
                        color="#ECEFF1"
                        style="border: 1px solid #d3d4e6"
                        class="mt-1"
                      >
                        <v-card-text style="width: 100%">
                          <div v-if="script.id===vulners"
                            id="7"
                            v-html="vulnersAtag(script.output)"
                            class="vulners"
                            style="width: 100%; white-space: pre-line;"
                            ></div
                          >
                          <div v-else-if="checkVulnerable(script.output)"
                            id="7"
                            class="vulners"
                            style="width: 100%; white-space: pre-line;"
                            >{{ script.output }}</div
                          >
                          <pre v-else
                            id="7"
                            class="pa-0"
                            style="width: 100%; white-space: pre-wrap;"
                            >{{ script.output }}</pre
                          >
                        </v-card-text>
                      </v-card>
                    </div>
                  </div>
                </div>
              </div>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </div>
    </div>
  </v-container>
</template>

<script>
import axios from 'axios';
import {details} from './serviceDetails.json';

export default {
  data() {
    return {
      helpDs: [],
      vulners:"vulners",
      description:"",
      panel: [],
      offsetTop: false,
      scrollTop: 0,
      target: null,
      ports: [],
      scripts: [],
      openedPorts: 0,
      filteredPorts: 0,
      closedPorts: 0,
      criticalScripts: [],
      highScripts: [],
      mediumScripts: [],
      lowScripts: [],
      openeded: [],
      isPanelOpen: false,
      scanresultsportdata: [
        {
          count: "10",
          port: "Ports",
          opened: "OPENED",
          ports: "tcp/8888 (syn-ack)",
        },
        {
          count: "996",
          port: "Ports",
          opened: "FILTERED",
          ports: "no-responses",
        },
        {
          count: "1",
          port: "Port",
          opened: "CLOSED",
          ports: "--",
        },
      ],
      scanresultsscriptdata: [
        {
          count: "1",
          scripts: "scripts",
          level: "CRITICAL",
          scriptname: "",
        },
        {
          count: "1",
          scripts: "scripts",
          level: "MEDIUM",
          scriptname: "",
        },
        {
          count: "1",
          scripts: "scripts",
          level: "LOW",
          scriptname: "",
        },
      ],
    };
  },
  props: {
    host: Object,
  },

  computed: {},
  
  created(){
    this.getNSEscript();
    // this.getScriptDesc();  
  },

  methods: {
    getServiceDesc:function(service){
      console.log(service)
      if(service != undefined){
        if(details[service.toLowerCase()]){
          return details[service.toLowerCase()];
        }else{
          return "There's no details";
        }
      }else{
        return "There's no details";
      }
    },
    checkVulnerable:function(value){
      return value.includes("VULNERABLE:")?true:false;
    },
    vulnersAtag: function(value){
      var fullText=``;
      const arr=value.split("\t");
      arr.forEach((e)=>{
        console.log(e);
        var eText=``;
        if(e.includes("https")){
          eText=`<a href="`+e+`" target="_blank">`+e+` </a>\t`;
        }else{
          eText=e+`\t`;
        }
        fullText+=eText;
      })
      // console.log(fullText);
      document.getElementsByClassName('vulners').innerHTML=fullText;
      return document.getElementsByClassName('vulners').innerHTML;
    },
    showTooltip: function(value){
      var e1=value.target.closest('.scriptwrap');
      var tooltip=e1.querySelector('.desTooltip');
      tooltip.style.display="block";
    },
    offTooltip: function(value){
      var e1=value.target.closest('.scriptwrap');
      var tooltip=e1.querySelector('.desTooltip');
      var style=tooltip.style;
      style.display="none";
    },
    getScriptDesc: function(value){
      // console.log(value);
      var index1=this.helpDs.findIndex((e)=>e.script_name===value);
      // console.log(index1);
      if(index1!=-1){
        return this.helpDs[index1].description;
      }else{
        return "not found";
      }
    },
    getNSEscript: function () {
      axios
        .get(`https://rav.wikisecurity.net/php-api.php/help/getallhelp`)
        .then((response) => {
          // console.log('Users get: ', response.data);
          this.helpDs = response.data;
        });
    },
    openScriptPanel(port, script) {
      const panel = document.querySelector(
        `#${port.protocol + "-" + port.portid}`
      );

      const clickPanel = () => {
        return new Promise((resolve) => {
          const handleClick = () => {
            panel.removeEventListener("transitionend", handleClick);
            resolve();
          };

          panel.addEventListener("transitionend", handleClick);
          panel.click();
        });
      };

      const scrollToElement = (element) => {
        return new Promise((resolve) => {
          requestAnimationFrame(() => {
            element.scrollIntoView({ behavior: "smooth", block: "center" });
            resolve();
          });
        });
      };

      if (panel) {
        clickPanel().then(() => {
          const elementId = `${port.portid}-${script.id}`;
          const element = document.getElementById(elementId);
          if (element) {
            scrollToElement(element);
          }
        });
      }
    },

    openpanal(port) {
      const panel = document.querySelector(
        `#${port.protocol + "-" + port.portid}`
      );
      if (panel) {
        panel.scrollIntoView({ behavior: "smooth", block: "start" });
        panel.click();
      }
    },

    ClickEvent() {
      // console.log("Target div clicked");
      if (this.offsetTop) {
        this.offsetTop = false;
      }
      if (!this.offsetTop) {
        this.offsetTop = true;
      }
    },

    toTop() {
      this.$refs.gototop.scrollIntoView({ behavior: "smooth" });
      // console.log(this.helpD);
      // console.log("correct");
    },
  },

  mounted() {
    console.log(this.ports);
    if (this.host["xml-nmap"].host && this.host["xml-nmap"].host[0].ports) {
      const totalPorts = this.host["xml-nmap"].host[0].ports[0];
      if (totalPorts) {
        this.ports = totalPorts.port;
        if (this.ports) {
          console.log("this.ports")
          console.log(this.ports.filter(item=>item.state[0].state == "open"))
          this.ports.forEach((port) => {
            if (port.state[0].state === "filtered") {
              this.filteredPorts++;
            } else if (port.state[0].state === "open") {
              // console.log(port.service[0].name)
              this.openedPorts++;
            } else {
              this.closedPorts++;
            }
          });
        }
        if (totalPorts.extraports && totalPorts.extraports[0]) {
          if (totalPorts.extraports[0].state == "closed") {
            this.closedPorts = totalPorts.extraports[0].count;
          } else {
            this.filteredPorts = totalPorts.extraports[0].count;
          }
        }
      }
    }

    if (this.ports) {
      this.ports.forEach((port) => {
        const totalScripts = port;
        if (totalScripts.script) {
          totalScripts.script.forEach((script) => {
            const { score } = script;
            if (score >= 0 && score < 4) {
              this.lowScripts.push({ port, script });
            } else if (score >= 4 && score < 7) {
              this.mediumScripts.push({ port, script });
            } else if (score >= 7 && score < 9) {
              this.highScripts.push({ port, script });
            } else if (score >= 9 && score <= 10) {
              this.criticalScripts.push({ port, script });
            }
          });
        }
      });
    }
  },
};
</script>

<style>
.v-expansion-panel-header > *:not(.v-expansion-panel-header__icon){
  flex:0 !important;
}
.v-icon::after{
  width: fit-content;
}
.desTooltip {
  display: none;
  position: absolute;
  bottom:25px;
  color: white;
  border: 1px solid rgba(97, 97, 97, 0.8);;
  border-radius: 5px;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  z-index: 400000;
  width: 100%; 
  word-break: keep-all;
  background-color: rgba(97, 97, 97, 0.8);
  text-transform: none;
  font-weight: normal;
}
.v-expansion-panel-content /deep/ .v-expansion-panel-content__wrap {
  padding: 0 4px 0px !important;
}

.v-expansion-panel--active > .v-expansion-panel-header {
  min-height: 30px !important;
}

.v-expansion-panel-header {
  min-height: 30px !important;
}
.scriptwrap{
  display: flex;
  align-items: center;
  position: relative;
  /* border:1px solid black; */
  height: 25px;
}
.vulners{
  font-family: monospace;
}
</style>
