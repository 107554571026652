<template>
  <div>
    <v-container class="d-flex" style="height: 100vh">
      <v-layout class="my-md-auto">
        <v-flex>
          <div
            class="text-h4 font-weight-black"
            style="text-align: center; margin-bottom: 11px; margin-top: -11px"
          >
            "This is a Demo System"
          </div>
          <v-card
            outlined
            elevation="2"
            tile
            class="mx-auto justify-center"
            style="border: 1px solid lightgray"
            height="500"
            width="900"
          >
            <v-row>
              <v-col cols="12" md="6">
                <div class="pa-1" height="500px" width="450px">
                  <v-img :src="loginImgURL" height="490" width="auto"></v-img>
                </div>
              </v-col>
              <v-col cols="12" md="6" v-show="!firstLoginMenu">
                <div class="pr-6 mt-16">
                  <v-card
                    v-if="error"
                    class="pa-2 mb-2"
                    outlined
                    elevation="1"
                    dark
                    color="red"
                  >
                    Incorrect User, Password, OTP, or expired Password
                  </v-card>
                  <div class="my-1">Username</div>
                  <v-text-field
                    solo
                    tabindex="6"
                    dense
                    v-model="formUser"
                    ref="usernameInput"
                    @blur="validateUser(formUser, formPassword)"
                  >
                  </v-text-field>

                  <div class="my-1">Password</div>
                  <v-text-field
                    :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                    :type="show ? 'text' : 'password'"
                    @click:append="show = !show"
                    solo
                    dense
                    tabindex="7"
                    required
                    v-model="formPassword"
                    @blur="getUser(formUser, formPassword)"
                  >
                  </v-text-field>

                  <div class="my-1" v-show="!firstLogin">OTP</div>
                  <v-text-field
                    solo
                    dense
                    required
                    tabindex="8"
                    v-model="formOTP"
                    v-show="!firstLogin"
                    :disabled="firstLogin"
                    @keydown.enter="tryLogin(formUser, formPassword)"
                  >
                  </v-text-field>
                  <v-row align="center" justify="end" class="pr-3 pt-1">
                    <v-btn
                      v-if="!firstLogin"
                      elevation="2"
                      class="mr-md-5"
                      style="border: 1px solid gainsboro"
                      :loading="loading"
                      :disabled="loading"
                      @click="tryLogin(formUser, formPassword)"
                    >
                      Login
                    </v-btn>
                    <v-btn
                      v-else
                      elevation="2"
                      class="mr-md-5"
                      style="border: 1px solid gainsboro"
                      :loading="loading"
                      :disabled="loading"
                      @click="
                        $store.commit('firstLogin');
                        $store.commit('setUser', userData);
                      "
                    >
                      First Login
                    </v-btn>
                  </v-row>
                </div>
              </v-col>

              <!-- Second screen (only for first time login) -->
              <v-col class="pa-4" v-show="firstLoginMenu">
                <v-card
                  v-if="error"
                  class="pa-2 mb-2"
                  outlined
                  style="max-width: 95%"
                  elevation="1"
                  dark
                  color="red"
                >
                  Incorrect Old Password, OTP or no matching New Passwword
                </v-card>
                <div class="mt-3">Old Password</div>
                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  solo
                  dense
                  required
                  hide-details=""
                  v-model="oldPassword"
                  @click:append="show = !show"
                >
                </v-text-field>
                <div class="my-1">New Password</div>
                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  solo
                  dense
                  required
                  hide-details=""
                  v-model="newPassword"
                  @click:append="show = !show"
                >
                </v-text-field>
                <div class="my-1">Confirm New Password</div>
                <v-text-field
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  solo
                  dense
                  required
                  hide-details=""
                  v-model="confirmNewPassword"
                  @click:append="show = !show"
                >
                </v-text-field>
                <div style="height: 125px; width: 125px">
                  <canvas
                    id="first-qr"
                    style="max-height: 120px; max-width: 120px"
                  ></canvas>
                </div>
                <div>OTP</div>
                <v-text-field 
                  soloz 
                  dense 
                  required 
                  hi 
                  v-model="OTPInput" 
                  @keydown.enter="tryFirstLogin()">
                </v-text-field>
                <v-row align="center" justify="end" class="pr-3">
                  <v-btn
                    elevation="2"
                    class="mr-md-5"
                    style="border: 1px solid gainsboro"
                    :loading="loading"
                    :disabled="loading"
                    @click="tryFirstLogin()"
                  >
                    Login
                  </v-btn>
                </v-row>
              </v-col>
            </v-row>
          </v-card>
        </v-flex>
      </v-layout>
    </v-container>
  </div>
</template>

<script>
import axios from "axios";
import {
  siteName,
  apiDateFormat,
  PTestApi,
  dateFormat,
} from "@/mixins/commons";
import { authenticator } from "otplib";
import QRCode from "qrcode";
import dayjs from "dayjs";
import EventBus from "@/plugins/EventBus.js";


var utc = require("dayjs/plugin/utc");
dayjs.extend(utc);
export default {
  name: `Login`,
  data: () => ({
    group: ``,
    show: false,
    loading: false,
    defaultOTP: ``,
    formUser: ``,
    formPassword: ``,
    formOTP: ``,
    oldPassword: ``,
    newPassword: ``,
    confirmNewPassword: ``,
    OTPInput: ``,
    error: false,
    firstLogin: false,
    userData: {},
    demoOTP: 502986,
  }),
  computed: {
    loginImgURL: function () {
      const ran = Math.floor(Math.random() * 92) + 1;
      console.log(ran);
      return `/image/nasa (${ran}).jpg`;
      // return `https://wiki-lab.wikisecurity.net/images/nasa%20(${ran}).jpg`
    },
    firstLoginMenu: function () {
      return this.$store.state.firstTimeLogin;
    },
    savedUserData: function () {
      return this.$store.state.userData;
    },
  }, 
  methods: {
    tryLogin: function () {
      this.error = false;
      this.loading = true;

      const exdata = dayjs(this.userData.expire_date).format(dateFormat);
      const today = dayjs().format(dateFormat);
      const expiredays = dayjs(exdata).diff(today, "day");
      if (this.userData?.secret) {
        this.defaultOTP = authenticator.generate(this.userData.secret);
        if (this.defaultOTP == this.formOTP || 
        this.formOTP == this.demoOTP && this.formUser == "rav-demo-tz" || 
        this.formOTP == this.demoOTP && this.formUser == "rav-demo-rw" ||
        this.formOTP == this.demoOTP && this.formUser == "rav-demo-vn"
      ) {
          var user = this.userData;
          const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
          axios
            .post(PTestApi, {
              uid: user.uid,
              timestamp: now,
              url: "/user/login/success",
            })
            .catch((error) => {
              console.error(error.response);
            });
          axios.get(`https://api.ipify.org/?format=json`).then((response) => {
            axios
              .post(PTestApi, {
                uid: user.uid,
                ip: response.data.ip,
                timestamp: now,
                url: "/log/login/success",
              })
              .then((response) => {
                console.log(response.data);
              })
              .catch((error) => {
                console.error(error.response);
              });
          });

          this.$store.commit("setUser", this.userData);
          console.log(this.userData);
          this.setGroupPermission();
          this.$store.commit("login");

          if (expiredays <= 10) {
            this.$router.push("pwalert");
            EventBus.$emit("openPwalert");
          } else {
            this.$router.push("status");
          }
        } else {
          this.error = true;
          this.loginError(this.userData.id);
        }
        this.loading = false;
      } else {
        this.error = true;
        if (this.userData?.id) {
          this.loginError(this.userData.id);
        }
      }

      this.loading = false;
    },

    loginError(userid) {
      axios
        .get(`https://api.ipify.org/?format=json`)
        .then((response) => {
          axios
            .post(PTestApi, {
              id: userid,
              ip: response.data.ip,
              url: "/log/login/fail",
            })
            .then((response) => {
              console.log(response.data);
            });
        })
        .finally(() => {
          console.warn("Failed attempt registered");
        });
    },
    tryFirstLogin: function () {
      this.error = false;
      this.loading = true;
      
      if (
        this.newPassword != this.confirmNewPassword ||
        this.newPassword == "" ||
        this.oldPassword == "" ||
        this.confirmNewPassword == ""
      ) {
        // console.log(1);
        this.loginError(this.savedUserData.id);
        this.error = true;
      } else {
        this.defaultOTP = authenticator.generate(this.savedUserData.secret);
        if (this.defaultOTP == this.OTPInput) {
          axios
            .post(PTestApi, {
              id: this.savedUserData.id,
              password: this.oldPassword,
              url: "/user/login",
            })
            .then((response) => {
              if (response.data[0]) {
                var user = this.savedUserData;
                user.start_time = dayjs
                  .utc(user.start_time)
                  .format(apiDateFormat);
                user.end_time = dayjs.utc(user.end_time).format(apiDateFormat);
                user.password = this.newPassword;
                user.url = "/user/modify";
                axios
                  .post(PTestApi, user)
                  .then((response) => {
                    console.log(response.data);
                  })
                  .finally(() => {
                    const now = dayjs().format("YYYY-MM-DD HH:mm:ss");
                    axios
                      .post(PTestApi, {
                        uid: user.uid,
                        timestamp: now,
                        url: "/user/login/success",
                      })
                      .catch((error) => {
                        console.error(error.response);
                      });
                    axios
                      .get(`https://api.ipify.org/?format=json`)
                      .then((response) => {
                        axios
                          .post(PTestApi, {
                            uid: user.uid,
                            ip: response.data.ip,
                            timestamp: now,
                            url: "/log/login/success",
                          })
                          .then((response) => {
                            console.log(response.data);
                          })
                          .catch((error) => {
                            console.error(error.response);
                          });
                      });
                    this.$store.commit("login");
                    this.setGroupPermission();
                    this.$router.push("status");
                  });
                this.$store.commit("login");
                this.setGroupPermission();
                this.$router.push("status");
              } else {
                this.error = true;
                this.loginError(this.savedUserData.id);
              }
            })
            .catch((error) => {
              console.error(error.response);
            });
        } else {
          // console.log(2);
          this.loginError(this.savedUserData.id);
          this.error = true;
        }
      }
      this.loading = false;
    },
    getUser: function (user, password) {
      this.error = false;
      this.loading = true;
      this.firstLogin = false;
      axios
        .post(PTestApi, { id: user, password: password, url: "/user/login" })
        .then((response) => {
          this.userData = response.data[0];
          if (this.userData.login_count > 0) {
            this.firstLogin = false;
          } else if (this.userData.login_count === 0) {
            // console.log(this.userData);
            this.firstLogin = true;
            this.createQR(this.userData.id, this.userData.secret);
          } else {
            this.error = true;
          }
        })
        .catch((error) => {
          console.error(error.response);
          this.error = true;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    validateUser: function (user, password) {
      this.error = false;
      this.loading = true;
      this.firstLogin = false;
      axios
        .post(PTestApi, { id: user, password: password, url: "/user/login" })
        .then((response) => {
          this.userData = response.data[0];
          if (this.userData?.login_count > 0) {
            this.firstLogin = false;
          } else if (this.userData?.login_count == 0) {
            this.firstLogin = true;
            this.createQR(this.userData.id, this.userData.secret);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          this.loading = false;
        });
    },
    createQR: function (user, secret) {
      this.userQR = user;
      const service = siteName;
      const otpauth = authenticator.keyuri(user, service, secret);
      const userQR = document.getElementById("first-qr");
      QRCode.toCanvas(userQR, otpauth, (err) => {
        if (err) {
          console.log("Error with QR");
          return;
        }
      });
    },
    setGroupPermission: function () {
      axios
        .get(`${PTestApi}/group/findAll`)
        .then((response) => {
          this.$store.commit("setGroupPermission", response.data);
          // console.log('Groups: ',response.data);
        })
        .catch((err) => {
          console.warn("oops: ", err);
        });
    },
  },
  mounted() {
    this.$refs.usernameInput.focus();
    if (this.savedUserData.id && this.savedUserData.secret) {
      this.createQR(this.savedUserData.id, this.savedUserData.secret);
    }
    
  },
};
</script>

<style scoped>
.v-text-field.v-text-field--solo.v-input--dense /deep/ .v-input__control {
  min-height: 32px;
  max-width: 95%;
  margin-bottom: 6px;
}

.v-icon /deep/ .v-icon {
  font-size: 20px;
}
</style>
